import VueRouter from 'vue-router';
import Login from './views/Login.vue';
import Registrazione from './views/Registrazione.vue';
import ResetPassword from './views/ResetPassword.vue';

const routes = [
  { path: '/login', component: Login },
  { path: '/registrazione', component: Registrazione},
  { path: '/resetPassword', component: ResetPassword},
  { path: '/resetPassword/:token', component: ResetPassword},
  { path: '/', redirect: '/login' },
];

export default new VueRouter({ routes });
