<template>
  <main>
    <div class="dangelo-login" :class="{ loading: loading }">
      <img src="@/common/img/puff.svg" v-if="loading" />
      <form @submit.prevent="login" v-else>
        <br />
        <h2><span>D</span><span style="letter-spacing: 16px">'ANGELO</span></h2>
        <p class="label" style="margin-top: 0; margin-bottom: 0; letter-spacing: 12px">
          ACCONCIATORI
        </p>
        <div class="logo-border"><span id="logo-claim"> SINCE 2000 </span></div>
        <br />
        <input type="email" v-model="username" placeholder="inserisci la tua e-mail" />
        <br />
        <input type="password" v-model="password" placeholder="inserisci la tua password" />
        <span v-if="error.length > 0" id="error">{{ error }}</span>
        <button id="login-button" class="btn">Login</button>
        <router-link align="center" :to="'/registrazione'"
          >Non hai un account? Registrati</router-link
        ><br />
        <router-link to="/resetPassword">Password Dimenticata?</router-link>
        <br />
      </form>
    </div>
    <pop-up></pop-up>
  </main>
</template>

<script>
import PopUp from "../../common/components/PopUp.vue";
export default {
  data() {
    return {
      username: "",
      password: "",
      error: "",
      loading: false
    };
  },
  components: {
    PopUp
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        await this.$root.login(this.username, this.password);
      } catch (e) {
        this.loading = false;
        if (e.status) {
          switch (e.status) {
            case 403:
            default:
              this.error = "Username o Password invalidi.";
          }
        } else this.error = e;
      }
    },
    onPasswordForgot() {
      this.$root.$emit("popup", {
        message: "Passa da noi o chiamaci per ripristinare la tua password",
        mandatory: true,
        actions: [
          {
            class: "active",
            name: "Ok"
          }
        ]
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/style/style.scss";

main {
  width: 100%;
  height: 100%;
  // background: url('../../common/img/login.png') center no-repeat;
  background-size: cover;
  /*@media (prefers-color-scheme: dark) {
    background-image: url('../../common/img/login_dark.png')
  }*/
  background-color: #fafafa;
  @media (prefers-color-scheme: dark) {
    background-color: #000;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.dangelo-login {
  overflow: hidden;
  text-align: center;
  @include card;
  background-color: #ffffff;
  @media (prefers-color-scheme: dark) {
    background-color: #111111;
    border: none;
    box-shadow: none;
  }
  @media (prefers-color-scheme: dark) and(max-width: 768px) {
    background-color: black;
  }

  width: 80%;
  max-width: 350px;
  // transition: max-height .4s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: $card-shadow-front;
  padding: 2em;
  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100px;
  }
  #error {
    display: block;
    color: white;
    text-align: center;
    background-color: $color-red;
    margin: 0.5em auto;
    padding: 0.5em;
    border-radius: $card-radius;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  .signup-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border: none;
    border-bottom: $card-border;
    border-bottom-width: 1px;
    @media (prefers-color-scheme: dark) {
      color: #eeeeee;
    }

    padding: 1em 0.5em;
    width: 100%;
    font-size: 16px;
    margin-top: 1em;
    // transition: .2s;
    box-sizing: border-box;
    background-color: transparent;
    &:focus {
      outline: none;
      color: $color-accent;
      border-color: $color-accent;
      @media (prefers-color-scheme: dark) {
        color: white
      }
    }
  }
  #login-button {
    &:not(:disabled) {
      background: linear-gradient(45deg, $color-border, $color-accent);
      color: white;
    }
    margin: 2em 0;
    width: 100%;
    border: none;
  }
  @media screen and(max-width: 768px) {
    box-sizing: border-box;
    max-height: 100% !important;
    border: none;
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    h2 {
      font-size: 2em;
    }
    #login-button {
      padding: 1.5em;
    }
  }
  h2 {
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>

.logo-border {
  width: 70%;
  margin: 0 auto;
  border-bottom: 1px solid black;
  text-align: center;
  @media (prefers-color-scheme: dark) {
    border-color: white;
  }
  #logo-claim {
    font-size: 12px;
    background-color: rgb(255, 255, 255);
    padding: 0px 10px;
    position: relative;
    bottom: -0.8em;
    @media (prefers-color-scheme: dark) {
      background-color: #111;
    }
  }
}
</style>
