<template>
    <div style="height: 100%">
        <router-view/>
        <Snackbar />
    </div>
</template>
<script>
import Snackbar from '@/common/components/Snackbar.vue'
export default {
    components: {
        Snackbar
    }
}
</script>