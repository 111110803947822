import Vue from 'vue/dist/vue';
import './registerServiceWorker';
import VueRouter from 'vue-router';
import router from './login/router';
import ky from './network';
import App from './login/App';

Vue.use(VueRouter);
Vue.config.productionTip = false;

(async function () {
  try {
    let sessione = (await ky.get("/api/Sessione").json()).sessione;
    if (sessione) {
      if (sessione.utente) document.location.replace('/utente');
      if (sessione.parrucchiere) document.location.replace('/parrucchiere');
      if (sessione.segreteria) document.location.replace(sessione.segreteria.kiosk ? '/kiosk.html' : '/segreteria');
    } else {
      initVue();
    }
  } catch {
    initVue();
  }
})()

function initVue() {
  new Vue({
    router,
    render: h => h(App),
    methods: {
      async login(username, password) {
        try {
          await ky.post('/api/Login', {
            headers: {
              authorization: `Basic ${btoa(username + ":" + password)}`
            }
          }).json()
          document.location.reload();
        } catch (e) {
          throw e.response
        }
      }
    }
  }).$mount('#app');
}
